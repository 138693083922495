/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { HeadersMap, XRPCError } from '@atproto/xrpc'
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'

export interface QueryParams {
  /** Another user sipz_id. */
  sipz_id_other: string
}

export type InputSchema = undefined

export interface OutputSchema {
  /** circle list */
  result?: Circle_ref[]
  [k: string]: unknown
}

export interface CallOptions {
  signal?: AbortSignal
  headers?: HeadersMap
}

export interface Response {
  success: boolean
  headers: HeadersMap
  data: OutputSchema
}

export function toKnownErr(e: any) {
  return e
}

export interface Circle_ref {
  id?: string
  circle_name?: string
  can_manage?: boolean
  circle_type?: number
  description?: string
  is_public?: boolean
  /** The circle photo. */
  photo?: string
  [k: string]: unknown
}

export function isCircle_ref(v: unknown): v is Circle_ref {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.getSharedCircles#circle_ref'
  )
}

export function validateCircle_ref(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.server.getSharedCircles#circle_ref', v)
}
